"use client"
import { useRef, useState } from "react"
import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from "@knocklabs/react"
import "@knocklabs/react/dist/index.css"
import { KnockNotificationListener } from "@/components/knock-notification-feed/knock-notification-listener"
import { useUser } from "@supabase/auth-helpers-react"
import { MaxWidthContainer } from "@/components/max-width-container"

const KnockNotificationFeed = () => {
  const user = useUser()
  const notifButtonRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  if (!user || !user.id) return null

  return (
    <KnockProvider
      apiKey={process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY}
      userId={user.id}
    >
      <KnockFeedProvider
        colorMode="dark"
        feedId={process.env.NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID}
      >
        <>
          <div className="fixed w-full right-0 top-10 z-50 sm:bottom-32 sm:right-0 sm:top-auto">
            <MaxWidthContainer className="relative">
              <div className="absolute rounded-full bg-card px-1 py-1 right-0">
                <NotificationIconButton
                  ref={notifButtonRef}
                  onClick={(e) => setIsVisible(!isVisible)}
                />
              </div>
            </MaxWidthContainer>
          </div>
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </>
        <KnockNotificationListener />
      </KnockFeedProvider>
    </KnockProvider>
  )
}

export { KnockNotificationFeed }
