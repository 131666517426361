import {
  LikesTableRow,
  LikesTableRowWithGeneration,
  Tables,
} from "@/utils/types/database"
import { StateCreator } from "zustand"
import { produce } from "immer"

interface State {
  // these likes get stored to keep track
  // of liked generations across the app
  likes: Record<number, LikesTableRow>

  // these likes get stored to render the liked generations
  // in the liked generations page
  likedGenerations: Array<LikesTableRowWithGeneration>
}

interface Actions {
  setLikes: (likedGenerations: Array<LikesTableRow>) => void
  appendLike: (like: LikesTableRow) => void
  appendLikes: (likes: Array<LikesTableRow>) => void
  removeLikeByGenerationId: (generationId: number) => void

  setLikedGenerations: (
    likedGenerations: Array<LikesTableRowWithGeneration>,
  ) => void
  removeLikedGenerationByGenerationId: (generationId: number) => void
  appendLikedGeneration: (generation: LikesTableRowWithGeneration) => void
  prependLikedGeneration: (generation: LikesTableRowWithGeneration) => void

  resetLikesStore: () => void
}

export type LikesStore = State & Actions

export const createLikesStore: StateCreator<LikesStore> = (set, get) => ({
  likes: {},
  setLikes: (likedGenerations: Array<Tables<"generations_likes">>) => {
    const likesMap = likedGenerations.reduce(
      (acc, like) => {
        acc[like.generation_id] = like
        return acc
      },
      {} as Record<string, LikesTableRow>,
    )
    set(() => ({ likes: likesMap }))
  },
  appendLike: (like: LikesTableRow) => {
    set(() =>
      produce(get(), (draft) => {
        draft.likes[like.generation_id] = like
      }),
    )
  },
  appendLikes: (likes: Array<LikesTableRow>) => {
    set(() =>
      produce(get(), (draft) => {
        likes.forEach((like) => {
          draft.likes[like.generation_id] = like
        })
      }),
    )
  },

  removeLikeByGenerationId: (generationId: number) => {
    set(() => {
      return produce(get(), (draft) => {
        if (draft.likes[generationId]) {
          delete draft.likes[generationId]
        }
      })
    })
  },

  likedGenerations: [],
  setLikedGenerations: (
    likedGenerations: Array<LikesTableRowWithGeneration>,
  ) => {
    set(() => ({ likedGenerations }))
  },
  removeLikedGenerationByGenerationId: (generationId: number) => {
    const likedGenerations = get().likedGenerations
    set(() => ({
      likedGenerations: likedGenerations.filter(
        (g) => g.generation.id !== generationId,
      ),
    }))
  },
  appendLikedGeneration: (generation: LikesTableRowWithGeneration) => {
    set(() =>
      produce(get(), (draft) => {
        draft.likedGenerations.push(generation)
      }),
    )
  },
  prependLikedGeneration: (generation: LikesTableRowWithGeneration) => {
    set(() =>
      produce(get(), (draft) => {
        draft.likedGenerations.unshift(generation)
      }),
    )
  },

  resetLikesStore: () => {
    set({
      likes: {},
      likedGenerations: [],
    })
  },
})
