"use client"
import { useEffect, useState } from "react"
import { clearAgeConsent, setAgeConsent } from "@/app/actions"
import { getCookie } from "cookies-next"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { track } from "@/services/analytics"

export function AgeDisclaimerRoadblock() {
  const ageConsentCookie = getCookie("age-consent")
  const hasAgeConsent = ageConsentCookie === "true"
  const [open, setOpen] = useState(!hasAgeConsent)

  useEffect(() => {
    if (hasAgeConsent) {
      setTimeout(() => {
        const body = document.querySelector("body")
        if (body) {
          body.classList.add("delayed-survey")
        }
      }, 1000)
    }
  }, [hasAgeConsent])

  return (
    <AlertDialog open={open} defaultOpen={hasAgeConsent}>
      <AlertDialogPortal>
        <AlertDialogOverlay className="backdrop-blur-md" />

        <form action={setAgeConsent}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>User Agreement</AlertDialogTitle>
              <AlertDialogDescription>
                By using Pornstars.AI, you acknowledge that you are at least 18
                years of age or the age of majority in the jurisdiction where
                you reside. You also acknowledge that you have read and
                understand our Terms of Service and Privacy Policy.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  track("age_consent", { consent: "false" })
                  clearAgeConsent()
                  setOpen(false)
                  window.location.href = "https://google.com"
                }}
              >
                Leave
              </AlertDialogCancel>
              <AlertDialogAction
                type="submit"
                onClick={() => {
                  track("age_consent", { consent: "true" })
                  setAgeConsent()
                  setOpen(false)
                }}
              >
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialogPortal>
    </AlertDialog>
  )
}
