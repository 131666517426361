import { StateCreator } from "zustand"

interface State {
  isSafeForWork: boolean
}

interface Actions {
  setIsSafeForWork: (isSafeForWork: boolean) => void
}

export type UiStore = State & Actions

export const createUiStore: StateCreator<UiStore> = (set, get) => ({
  isSafeForWork: false,
  setIsSafeForWork: (isSafeForWork) => set({ isSafeForWork }),
})
