import { cn } from "@/lib/utils"

interface TypographyProps {
  children: React.ReactNode
  className?: string
}
export function TypographyH1(props: TypographyProps) {
  const { children, className } = props
  return (
    <h1
      className={cn(
        "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
        className,
      )}
    >
      {children}
    </h1>
  )
}

export function TypographyH2(props: TypographyProps) {
  const { children, className } = props
  return (
    <h2
      className={cn(
        "scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0",
        className,
      )}
    >
      {children}
    </h2>
  )
}

export function TypographyH3(props: TypographyProps) {
  const { children, className } = props
  return (
    <h3
      className={cn(
        "scroll-m-20 text-2xl font-semibold tracking-tight",
        className,
      )}
    >
      {children}
    </h3>
  )
}

export function TypographyH4(props: TypographyProps) {
  const { children, className } = props
  return (
    <h4
      className={cn(
        "scroll-m-20 text-xl font-semibold tracking-tight",
        className,
      )}
    >
      {children}
    </h4>
  )
}

export function TypographyP(props: TypographyProps) {
  const { children, className } = props
  return <p className={cn("leading-7", className)}>{children}</p>
}

export function TypographyBlockQuote(props: TypographyProps) {
  const { children, className } = props
  return (
    <blockquote className={cn("mt-6 border-l-2 pl-6 italic", className)}>
      {children}
    </blockquote>
  )
}

export function TypographyLead(props: TypographyProps) {
  const { children, className } = props
  return (
    <p className={cn("text-xl text-muted-foreground", className)}>{children}</p>
  )
}

export function TypographyLarge(props: TypographyProps) {
  const { children, className } = props
  return (
    <div className={cn("text-lg font-semibold", className)}>{children}</div>
  )
}

export function TypographySmall(props: TypographyProps) {
  const { children, className } = props
  return (
    <small className={cn("text-sm font-medium leading-none", className)}>
      {children}
    </small>
  )
}

export function TypographyMuted(props: TypographyProps) {
  const { children, className } = props
  return (
    <p className={cn("text-sm text-muted-foreground", className)}>{children}</p>
  )
}
