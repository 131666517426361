import { cn } from "@/lib/utils"

interface MaxWidthContainerProps {
  children: React.ReactNode
  className?: string
  classNameRoot?: string
}
export function MaxWidthContainer(props: MaxWidthContainerProps) {
  return (
    <div className={cn("px-6 lg:px-8", props.classNameRoot)}>
      <div className={cn("max-w-7xl mx-auto", props.className)}>
        {props.children}
      </div>
    </div>
  )
}
