"use client"
import { useEffect } from "react"
import { useKnockFeed } from "@knocklabs/react"
import { toast } from "sonner"
import { useRouter } from "@/lib/i18n"

const KnockNotificationListener = () => {
  const { feedClient } = useKnockFeed()
  const router = useRouter()

  useEffect(() => {
    const onNotificationsReceived = ({ items }: { items: any[] }) => {
      // Whenever we receive a new notification from our real-time stream, show a toast
      // (note here that we can receive > 1 items in a batch)
      items.forEach((notification: any) => {
        console.log("notification", notification)
        const contentBlock = notification.blocks[0]
        const actionBlock = notification.blocks[1]
        console.log("actionBlock", actionBlock)
        console.log("contentBlock", contentBlock)

        toast.message(contentBlock.rendered, {
          duration: 5000,
          action: actionBlock?.buttons
            ? {
                label: actionBlock?.buttons[0].label,
                onClick: () => {
                  router.push(notification.data.primary_action_url)
                },
              }
            : null,
        })
      })
      // Optionally, you may want to mark them as "seen" as well
      feedClient.markAsSeen(items)
    }

    // Receive all real-time notifications on our feed
    feedClient.on("items.received.realtime", onNotificationsReceived)

    // Cleanup
    return () => {
      feedClient.off("items.received.realtime", onNotificationsReceived)
    }
  }, [feedClient, router])

  return null
}

export { KnockNotificationListener }
