import { GenerationsTableRowWithUsersProfile } from "@/utils/types/database"
import { produce } from "immer"
import { StateCreator } from "zustand"

export interface StateGeneration extends GenerationsTableRowWithUsersProfile {}

interface State {
  generations: Array<StateGeneration>
}

interface Actions {
  setGenerations: (gens: StateGeneration[]) => void
  appendGeneration: (gen: StateGeneration) => void
  appendGenerations: (gens: StateGeneration[]) => void

  incrementGenerationLikes: (generationId: number) => void
  decrementGenerationLikes: (generationId: number) => void
}

export type GenerationsFeedStore = State & Actions

export const createGenerationsFeedSlice: StateCreator<GenerationsFeedStore> = (
  set,
  get,
) => ({
  generations: [],

  setGenerations: (gens: StateGeneration[]) => {
    return set((state) => {
      return produce(state, (draftState) => {
        draftState.generations = gens
      })
    })
  },

  appendGeneration: (gen: StateGeneration) => {
    return set((state) => {
      return produce(state, (draftState) => {
        if (draftState.generations) {
          draftState.generations.push(gen)
        } else {
          draftState.generations = [gen]
        }
      })
    })
  },

  appendGenerations: (gens: StateGeneration[]) => {
    return set((state) =>
      produce(state, (draftState) => {
        if (draftState.generations) {
          draftState.generations.push(...gens)
        } else {
          draftState.generations = gens
        }
      }),
    )
  },

  incrementGenerationLikes: (generationId: number) => {
    return set((state) =>
      produce(state, (draftState) => {
        const generation = draftState.generations.find(
          (gen) => gen.id === generationId,
        )
        if (generation) {
          generation.likes_count++
        }
      }),
    )
  },
  decrementGenerationLikes: (generationId: number) => {
    return set((state) =>
      produce(state, (draftState) => {
        const generation = draftState.generations.find(
          (gen) => gen.id === generationId,
        )
        if (generation) {
          generation.likes_count--
        }
      }),
    )
  },
})
