"use client"

import { queueMigrateUserToSupabase } from "@/app/actions/auth.actions"
import { flushAnalytics, identify } from "@/services/analytics"
import { supabaseClient } from "@/services/supabase/supabase-client"
import { usePathname } from "@/lib/i18n"
import { useSearchParams } from "next/navigation"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
import { toast } from "sonner"
import { useRootStore } from "@/store/root-store"
import { useFeatureFlagEnabled } from "posthog-js/react"

export function SupabaseAuthListener() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const router = useRouter()
  const isMigrateFromFirebaseEnabled = useFeatureFlagEnabled(
    "migrate_from_firebase",
  )

  const { resetGenerateStore, resetLikesStore, resetMyGenerationsStore } =
    useRootStore((state) => state)

  useEffect(() => {
    const searchParamMessage = searchParams.get("message")
    const searchParamCode = searchParams.get("code")

    const searchParamError = searchParams.get("error")
    const searchParamErrorDescription = searchParams.get("error_description")

    if (searchParamError && searchParamErrorDescription) {
      toast.error(searchParamErrorDescription)
    }

    if (searchParamCode) {
      toast.success("Logged in successfully!", {
        position: "bottom-center",
      })
      router.replace(pathname)
      router.refresh()
    }

    if (searchParamMessage) {
      toast.message(searchParamMessage)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  useEffect(() => {
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange(async (event, session) => {
      // console.info("Supabase auth change:", event, session)

      if (event === "SIGNED_OUT") {
        // Reset the analytics service when the user signs out.
        flushAnalytics()

        resetGenerateStore()
        resetLikesStore()
        resetMyGenerationsStore()
      }

      if (event === "INITIAL_SESSION") {
        // Identify the user with the analytics service
        // as soon as the session is initialized.
        if (session?.user) {
          const { app_metadata, user_metadata, ...user } = session.user
          // flatten the user object
          // to make sure that we can properly
          // filter and segment users in PostHog.
          const flattenedUser = {
            ...user,
            ...user_metadata,
            ...app_metadata,
          }
          identify(session?.user.id, flattenedUser)
        }
      }

      if (event === "SIGNED_IN") {
        const isAlreadyMigrated =
          session?.user.app_metadata?.migrated_from_firebase
        const isPostMigrationUser =
          session?.user.app_metadata?.post_migration_user

        if (
          isMigrateFromFirebaseEnabled &&
          !isAlreadyMigrated &&
          !isPostMigrationUser &&
          session?.user.email
        ) {
          console.log("Migrating user to Supabase")
          await queueMigrateUserToSupabase({
            supabaseUserId: session?.user.id,
            email: session?.user.email,
          })
        }
        router.refresh()
      }
    })
    return () => {
      subscription?.unsubscribe()
    }
  }, [
    pathname,
    resetGenerateStore,
    resetLikesStore,
    resetMyGenerationsStore,
    router,
    isMigrateFromFirebaseEnabled,
  ])

  return null
}
