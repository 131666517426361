import { GenerationsTableRowWithUsersProfile } from "@/utils/types/database"
import { StateCreator } from "zustand"
import { produce } from "immer"

interface State {
  myGenerations?: Array<GenerationsTableRowWithUsersProfile>
  myRecentGenerations?: Array<GenerationsTableRowWithUsersProfile>
  myPendingGenerations?: Array<GenerationsTableRowWithUsersProfile>
}

interface Actions {
  setMyGenerations: (
    generations: Array<GenerationsTableRowWithUsersProfile>,
  ) => void
  appendMyGeneration: (generation: GenerationsTableRowWithUsersProfile) => void
  removeMyGeneration: (generation: GenerationsTableRowWithUsersProfile) => void
  prependMyGeneration: (generation: GenerationsTableRowWithUsersProfile) => void

  setMyRecentGenerations: (
    generations: Array<GenerationsTableRowWithUsersProfile>,
  ) => void

  setMyPendingGenerations: (
    generations: Array<GenerationsTableRowWithUsersProfile>,
  ) => void

  removeMyPendingGeneration: (
    generation: GenerationsTableRowWithUsersProfile,
  ) => void

  removeMyRecentGeneration: (
    generation: GenerationsTableRowWithUsersProfile,
  ) => void

  resetMyGenerationsStore: () => void
}

export type MyGenerationsStore = State & Actions

export const createMyGenerationsStore: StateCreator<MyGenerationsStore> = (
  set,
  get,
) => ({
  setMyGenerations: (
    myGenerations: Array<GenerationsTableRowWithUsersProfile>,
  ) => {
    return set(() => ({ myGenerations }))
  },

  appendMyGeneration: (generation: GenerationsTableRowWithUsersProfile) => {
    return set((state) => {
      return produce(state, (draftState) => {
        const myGenerations = state.myGenerations ?? []
        const exists = myGenerations.find((l) => l.id === generation.id)
        if (exists) {
          return
        }
        draftState.myGenerations = [...myGenerations, generation]
      })
    })
  },

  prependMyGeneration: (generation: GenerationsTableRowWithUsersProfile) => {
    return set((state) => {
      return produce(state, (draftState) => {
        const myGenerations = state.myGenerations ?? []
        const exists = myGenerations.find((l) => l.id === generation.id)
        if (exists) {
          return
        }
        draftState.myGenerations = [generation, ...myGenerations]
      })
    })
  },

  removeMyGeneration: (generation: GenerationsTableRowWithUsersProfile) => {
    return set((state) => {
      return produce(state, (draftState) => {
        const myGenerations = state.myGenerations ?? []
        const newMyGenerations = myGenerations.filter(
          (l) => l.id !== generation.id,
        )
        draftState.myGenerations = newMyGenerations
      })
    })
  },

  setMyPendingGenerations: (
    myPendingGenerations: Array<GenerationsTableRowWithUsersProfile>,
  ) => {
    return set((state) => {
      return produce(state, (draftState) => {
        draftState.myPendingGenerations = myPendingGenerations
      })
    })
  },

  removeMyPendingGeneration: (
    generation: GenerationsTableRowWithUsersProfile,
  ) => {
    return set((state) => {
      return produce(state, (draftState) => {
        const myPendingGenerations = state.myPendingGenerations ?? []
        const newMyPendingGenerations = myPendingGenerations.filter(
          (l) => l.id !== generation.id,
        )
        draftState.myPendingGenerations = newMyPendingGenerations
      })
    })
  },

  removeMyRecentGeneration: (
    generation: GenerationsTableRowWithUsersProfile,
  ) => {
    return set((state) => {
      return produce(state, (draftState) => {
        const myRecentGenerations = state.myRecentGenerations ?? []
        const newMyRecentGenerations = myRecentGenerations.filter(
          (l) => l.id !== generation.id,
        )
        draftState.myRecentGenerations = newMyRecentGenerations
      })
    })
  },

  setMyRecentGenerations: (
    myRecentGenerations: Array<GenerationsTableRowWithUsersProfile>,
  ) => {
    return set(() => ({ myRecentGenerations }))
  },

  resetMyGenerationsStore: () => {
    return set({
      myGenerations: [],
      myRecentGenerations: [],
      myPendingGenerations: [],
    })
  },
})
