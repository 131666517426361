"use client"

import { supabaseClient } from "@/services/supabase/supabase-client"
import { usePathname } from "@/lib/i18n"
import { useSearchParams } from "next/navigation"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
import { toast } from "sonner"

export function CCBillRedirectListener() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const router = useRouter()

  useEffect(() => {
    const checkAndRefreshSession = async () => {
      const searchParamSubscriptionId = searchParams.get("subscription_id")

      if (searchParamSubscriptionId) {
        toast.success("Your subscription has been created!", {
          position: "bottom-center",
        })

        await supabaseClient.auth.refreshSession()
        router.replace(pathname)
      }
    }

    checkAndRefreshSession()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
