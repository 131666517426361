import { type ClassValue, clsx } from "clsx"
import { Parser, createParser } from "nuqs"
import { twMerge } from "tailwind-merge"
import { Buffer } from "buffer"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Unslugifies a slugified string.
 *
 * @param {string} slug slugified string.
 * @returns {string} un-slugified string.
 */
export const unslugify = (slug: string) =>
  slug
    .replace(/\-/g, " ")
    .replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(),
    )

/**
 * Encode an array of items into a base64 string in the querystring.
 * Items are JSON-encoded before being base64-encoded.
 *
 * @param itemParser Parser for each individual item in the array
 * @returns A parser that can be used with `useQueryState`
 */
export function parseAsBase64ArrayOf<ItemType>(itemParser: Parser<ItemType>) {
  return createParser<ItemType[]>({
    parse: (query: string) => {
      if (!query) return null
      if (query === "") return []

      try {
        const decoded = Buffer.from(query, "base64").toString("utf-8")
        const parsed = JSON.parse(decoded)

        if (!Array.isArray(parsed)) {
          return null
        }
        return parsed
          .map((item) => itemParser.parse(item))
          .filter((item) => item !== null) as ItemType[]
      } catch {
        return null
      }
    },
    serialize: (values: ItemType[]) => {
      if (values.length === 0) {
        return ""
      }
      const serialized = values.map((item) =>
        itemParser.serialize ? itemParser.serialize(item) : item,
      )
      const stringified = JSON.stringify(serialized)
      const encoded = Buffer.from(stringified).toString("base64")
      return encoded
    },
    eq(a: ItemType[], b: ItemType[]) {
      if (a === b) {
        return true // Referentially stable
      }
      if (a.length !== b.length) {
        return false
      }
      return a.every((value, index) => itemParser.eq!(value, b[index]!))
    },
  })
}
