import { StateCreator } from "zustand"
import { produce } from "immer"
import { Tables } from "@/utils/types/database"

interface State {
  generateSelectedModelId?: string | null
  generateSelectedModel?: Tables<"models"> | null
  isGenerating: boolean
}

interface Actions {
  setGenerateSelectedModelId: (model: string | null) => void
  setGenerateSelectedModel: (model: Tables<"models"> | null) => void
  setIsGenerating: (isGenerating: boolean) => void

  resetGenerateStore: () => void
}

export type GenerateStore = State & Actions

export const createGenerateStore: StateCreator<GenerateStore> = (set, get) => ({
  isGenerating: false,

  setGenerateSelectedModelId: (modelId: string | null) =>
    set(
      produce((state) => {
        state.generateSelectedModelId = modelId
      }),
    ),

  setGenerateSelectedModel: (model: Tables<"models"> | null) => {
    return set(
      produce((state) => {
        state.generateSelectedModel = model
      }),
    )
  },

  setIsGenerating: (isGenerating: boolean) => {
    return set(
      produce((state) => {
        state.isGenerating = isGenerating
      }),
    )
  },

  resetGenerateStore: () => {
    return set({
      generateSelectedModelId: null,
      generateSelectedModel: null,
      isGenerating: false,
    })
  },
})
