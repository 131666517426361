import { createStore } from "zustand/vanilla"
import { devtools, persist } from "zustand/middleware"
import {
  GenerationsFeedStore,
  createGenerationsFeedSlice,
} from "@/store/feed-store"
import { LikesStore, createLikesStore } from "@/store/likes-store"
import {
  createMyGenerationsStore,
  MyGenerationsStore,
} from "@/store/my-generations-store"
import { GenerateStore, createGenerateStore } from "@/store/generate-store"
import { UiStore, createUiStore } from "@/store/ui-store"

export type RootStore = GenerationsFeedStore &
  LikesStore &
  MyGenerationsStore &
  GenerateStore &
  UiStore

export const createRootStore = () => {
  const store = createStore<RootStore>()(
    devtools(
      persist(
        (...args) => ({
          ...createGenerationsFeedSlice(...args),
          ...createLikesStore(...args),
          ...createMyGenerationsStore(...args),
          ...createGenerateStore(...args),
          ...createUiStore(...args),
        }),
        { name: "RootStore" },
      ),
      {
        enabled:
          typeof window !== "undefined" &&
          process.env.NODE_ENV !== "production",
      },
    ),
  )

  return store
}
